import React from "react";
import { SEO } from "@/components/SEO";
import { Page } from "@/components/Page";
import { Signup } from "@/features/Authentication/Signup";
import { withoutAuthentication } from "@/features/Authentication/AccessRestriction";

function SignUpPage() {
  return (
    <Page variation="presession">
      <Signup />
    </Page>
  );
}

export default withoutAuthentication(SignUpPage);

export const Head = () => (
  <SEO title="Sign Up" description="Signup for a Jobber Developer account" />
);

import React, { useState } from "react";
import { navigate } from "gatsby";
import { Auth } from "@aws-amplify/auth";
import { showToast } from "@jobber/components/Toast";
import jwtDecode from "jwt-decode";
import { useDisplayError } from "@/hooks/useDisplayError";
import { ErrorCodes } from "@/utils/cognito/ErrorCodes";
import { ErrorMessages } from "@/utils/cognito/ErrorMessages";
import { CognitoCustomAttributes } from "@/context/user";
import { Signup } from "./Signup";

interface JwtSignupToken {
  name: string;
  email: string;
  signup_token: string;
}

export function SignupLoader() {
  const [isLoading, setIsLoading] = useState(false);
  const { displayError } = useDisplayError();

  // read signuptoken from url params
  const signupToken = new URLSearchParams(window.location.search).get(
    "signup_token",
  );

  const decodedJwt =
    (signupToken && jwtDecode<JwtSignupToken>(signupToken || "")) || undefined;

  return (
    <Signup
      name={decodedJwt?.name}
      email={decodedJwt?.email}
      isLoading={isLoading}
      onSignupAttempt={handleOnSignupAttempt}
    />
  );

  // eslint-disable-next-line max-statements
  async function handleOnSignupAttempt({
    name,
    email,
    password,
    accountType,
  }: {
    name: string;
    email: string;
    password: string;
    accountType: string;
  }) {
    setIsLoading(true);
    try {
      const customAttributes: CognitoCustomAttributes = {
        "custom:name": name,
        "custom:account_type": accountType,
        ...(signupToken && {
          "custom:sign_up_token": signupToken,
        }),
      };

      await Auth.signUp({
        username: email,
        password: password,
        attributes: customAttributes,
      });
      try {
        await Auth.signIn(email, password);
        showToast({
          message: "Logged in",
          variation: "success",
        });
      } catch (error: any) {
        const code = error?.code as undefined | ErrorCodes;

        switch (code) {
          case ErrorCodes.USER_NOT_CONFIRMED: {
            navigate("/email_confirmation", { state: { email, password } });
            showToast({
              message: "Account created",
              variation: "success",
            });
            break;
          }
          default: {
            throw error;
          }
        }
      }
    } catch (error: any) {
      const message = error?.message as undefined | ErrorMessages;

      switch (message) {
        case ErrorMessages.EMAIL_ALREADY_EXISTS: {
          displayError(["Email address already in use"]);
          break;
        }
        default: {
          displayError([
            "Unable to create account, please contact support or try again",
          ]);
        }
      }
    } finally {
      setIsLoading(false);
    }
  }
}
